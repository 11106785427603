<template>
  <div class="modal-card" data-ref="terms">
    <header class="modal-card-head">
      <h2>{{ getText('tm001') }}</h2>
    </header>
    <section class="modal-card-body">
      <div class="newlineStringStyle pl20">
        <ol>
          <li>{{ getText('tm003') }}</li>
          <li>{{ getText('tm004') }}</li>
          <li>{{ getText('tm005') }}</li>
        </ol>
      </div>
    </section>
    <footer class="modal-card-foot btnBar btnBarRight">
      <button class="button bg" @click.stop="$parent.close()">{{ getText('close', true) }}</button>
    </footer>
  </div>
</template>

<script>

export default {

  data()
  {
    return {
      pageText: [],
      generalText: [],
    }
  },

  mounted(){
    this.pageText = this.$tools.translatePage(null,'terms');
    this.generalText = this.$tools.translatePage('general');
  },

  methods:
  {
    getText(ref, general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == false )
          return t;
      }
    },

    confirm()
    {
      this.$emit('confirmed');
      this.$parent.close();
    }
  }
}
</script>
<style scoped>
  .newlineStringStyle{ white-space: pre-wrap; }
  ol li { margin-bottom: 0.7em; }
</style>