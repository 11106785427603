<template>
  <div class="pageContent freeReturn">
    <div class="container mt3 mb3">
      <div class="columns">
        <div class="column is-7" v-if="!hasPrinted">
          <h1 v-if="isLoading">{{ getText('fr001') }}</h1>
          <h1 v-if="!isLoading">{{ getText('fr002') }}</h1>
          <div v-if="!isLoading">
            <div>
              <p>{{ getText('fr003') }}</p>
              <p>{{ getText('fr004') }}</p>
              <p>{{ getText('fr007') }}</p>
            </div>
          </div>
        </div>

        <div v-else class="column is-7">
          <div v-if="!isJsonLabel">
            <h1 v-if="!isLoading">{{ getText('fr002') }}</h1>
            <p>{{ getText('fr012') }}<span v-if="emailSent">{{ getText('fr013') }}</span>.</p>
            <p v-if="dropOffLocatorUrl != ''">{{ getText('fr015') }} <a :href="dropOffLocatorUrl" target="_blank">{{ dropOffLocatorUrl }}</a></p>
          </div>
          
          <div v-else>
            <h1 v-if="isLoading">{{ getText('fr001') }}</h1>
            <h1 v-if="!isLoading && isReturnCode">{{ getText('fr008') }}</h1>
            <div v-if="!isLoading">
              <div>
                <p v-for="row in labelTextData" :key="row.id">{{row}}.</p>
                <p v-if="isInPost">{{ getText('fr014') }}</p>
                <p v-else>{{ getText('fr009') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="column">

          <ReturnItemSummary :items="data.items" />

          <div v-if="!isJsonLabel" class="p1">
            <b-checkbox v-model="sendToEmail" v-bind:false-value="0" v-bind:true-value="1">{{ getText('sendToEmail', true) }}</b-checkbox>
            <b-input 
              type="text"
               :class="hiliteEmail"
              v-model="customerEmail" 
              :disabled="sendToEmail != '1'" 
              :placeholder="getText('yourEmail', true)"></b-input>
          </div>
          <p :class="['has-text-centered', 'is-size-6', setOpacity()]">{{ getText('completeEmail', true) }}</p>
          <div class="btnBar btnBarRight">
            <b-button class="mt1 bg" v-if="!hasPrinted" :disabled="disableConfirmation" @click.stop="confirm">{{ getText('confirm', true) }}</b-button>
          </div>
        </div>
      </div>

      <div v-if="hasPrinted">
        <div v-if="!isJsonLabel">
          <div v-if="prepaid == true">{{ getText('fr005') }}</div>
          <div v-else>{{ getText('fr006') }}</div>
        </div>
        <div v-else>
          <div>{{ getText('fr011') }}</div>
        </div>

        <div>
          <b-button v-if="!isJsonLabel" class="bg mr2 mt1" :loading="isLoading" @click.stop="sendEmail" :disabled="!okToResendEmail">
            <span v-if="emailSent == true"><b-icon class="is-inline-block mr025 vab mtm1" icon="email"></b-icon> {{ getText('resend', true) }}</span>
            <span v-else><b-icon class="is-inline-block mr025 vab mtm1" icon="email"></b-icon> {{ getText('send', true) }}</span>
          </b-button>
          <b-button v-if="!isJsonLabel" class="bg mr2 mt1" :loading="isLoading" @click.stop="printLabel">
            <b-icon class="is-inline-block mr025 vab mtm1" icon="printer"></b-icon> {{ getText('reprint', true) }}
          </b-button>
          <b-tooltip :label="!isJsonLabel ? getText('fr011'):''" position="is-right">
            <b-button class="bg mt1" :loading="isLoading" @click.stop="startOver">
              <b-icon class="is-inline-block mr025 vab mtm1" icon="refresh"></b-icon> {{ getText('restart', true) }}
            </b-button>
          </b-tooltip>
        </div>
      </div>
    </div>
    
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import ReturnItemSummary from "@/components/ReturnItemSummary";

export default {
  name: 'FreeReturn',

  components: {
    ReturnItemSummary
  },

  data()
  {
    return {
      
      isLoading: false,
      hasPrinted: false,
      prepaid: true,
      pageText: [],
      generalText: [],
      sendToEmail: false,
      customerEmail: '',

      label: '',
      labelType: '',
      emailText: [],
      emailSent: false,
      labelTextData: [],
      isReturnCode: false,
      isJsonLabel: false,
      isInPost: false,
      dropOffLocatorUrl: '',
    }
  },
  props: [
    'data',
    'returnReasons'
  ],

  computed:
  {  
    disableConfirmation()
    {
      let state = this.settlementChoice == '' || this.hasPrinted == true;

      if(this.sendToEmail == '1') // The checkbox is ticked so we need to lock up the confirmation, until a valid email has been added
      {
        state = true;
        if(this.$tools.validEmail(this.customerEmail))
        {
          state = false;
        }
      }

      return state;
    },

    okToResendEmail()
    {
      let state = false;

      if(this.hasPrinted == true && this.sendToEmail == '1' && this.$tools.validEmail(this.customerEmail)) // The checkbox is ticked so we need to lock up the confirmation, until a valid email has been added
      {
        state = true;
      }

      return state;
    },

    hiliteEmail()
    {
      return this.sendToEmail == '1' && (this.$tools.validEmail(this.customerEmail) == false || this.$tools.validEmail(this.customerEmail) == null) ? 'hilite' : '';
    }
  },

  mounted()
  {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
    this.isLabelTypeJson();
    this.checkForDropOffLocator();
  },

  methods:
  {

    getText(ref,general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },

    confirm()
    {
      if(this.data.label && this.data.label != '')
      {
        this.label = this.data.label;
        this.labelType = this.data.courier_label_type;
        this.printLabel();

      } else
      {
        this.openErrorMessage('teal446');
      }
    },

    isLabelTypeJson() {
      this.isJsonLabel = this.data.courier_label_type == 'JSON';
      if (this.isJsonLabel == true) {
        this.getLabelData();
        this.hasPrinted = true;
      }
    },

    getLabelData(){
      let labelText = atob(this.data.label);

      this.labelTextData =[];
      let i = 0;
      Object.entries(JSON.parse(labelText)).forEach(entry => {
        let [key,value] = entry;

        // Anticipating that the label data might be used for responses other than return codes
        // let's add the checks now to determine which title gets to be rendered
        if (this.isReturnCode == false) {
          this.isReturnCode = key == 'returnCode';
        }

        // There is specific text to use for InPost
        if (this.isInPost == false) {
          this.isInPost = key === 'courier' && value === 'InPost';
        }


        if(typeof(value) != 'boolean'){
          if (!isNaN(Date.parse(value))) {
            value = this.formatDate(value, true);
            i++; // this ensures that the date will get rendered on it's own line
          }
          if (!this.labelTextData[i]){
            this.labelTextData[i] = '';
          }

          this.labelTextData[i] += this.getText(key, 'general') + value;
        }
      });
    },

    formatDate(date, time = null)
    {
      let d = new Date(date);
      let t = time ? ' ' + d.toLocaleTimeString() : '';
      return d.toLocaleDateString() + t;
    },

    printLabel()
    {
      let html = '';
      if(this.labelType.toUpperCase() == 'PDF')
      {
        let url = "data:application/pdf;base64," + this.label;
        html = '<iframe width="100%" height="100%" src="'+ encodeURI(url) + '"></iframe>';
      }
      else 
      {
        let image = new Image();
        image.src = "data:image/png;base64," + this.label;
        html  = '<style>html,body{width: 210mm;height:297mm;}img{max-height:100%;max-width:100%;}</style>';
        html += '<img src="' + image.src + '" />';
      }
      let w = window.open();
      w.document.title = 'Pertemba Returns Label';
      w.document.body.innerHTML = html;

      if(this.labelType.toUpperCase() != 'PDF') {
        setTimeout(()=>{
          w.print();
          w.close();
        },500 );
      }
      
      if(this.sendToEmail == '1'){
        this.sendEmail();
      }
      else
      {
        this.hasPrinted = true;
      }
    },

    sendEmail(){
      this.isLoading = true;

      let emailTextJson = this.$tools.translatePage('emailText');
      this.emailText = Object.assign({}, ...emailTextJson);

      this.addRefundReasonText();

      let data = {
        orderId: this.data.id,
        externalOrderId: this.data.external_id,
        itemsToRefund: this.data.items,
        emailText: this.emailText,
        firstName: this.data.first_name,
        customerEmail: this.customerEmail,
        label: this.label,
        labelType: this.labelType
      }

      this.$api.post('send_email_prep.php', data)
      .then(response => {

        if( response.data.status == 'success')
        {
          this.emailSent = true;
        }

        this.isLoading = false;
        this.hasPrinted = true;
      })
      .catch(() => {
        this.openErrorMessage('ivory423');
        this.isLoading = false;
        this.hasPrinted = true;
      });
    },

    addRefundReasonText()
    {
      for(let item of this.data.items) 
      {
        item['reasonText'] =  this.returnReasons.find(r => r.id == item.reason).value;
      }
    },

    startOver(){
      this.$emit('restart');
    },
    
    setOpacity()
    {
      return this.sendToEmail==1 && this.$tools.validEmail(this.customerEmail) == null ? '' : 'hideThis';
    },

    checkForDropOffLocator()
    {
      this.dropOffLocatorUrl = this.data.drop_off_locator_url;
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .b-radio + p { margin-left: 1.75em; }
  .hideThis { opacity: 0; }
</style>
