<!--
  ImageModal.vue
  This one just opens a large verssion of any product image
-->

<template>
  <div>
    <p class="image">
      <img :src="imageLink + image">
    </p>
  </div>
</template>

<script>
  export default {
    name: 'ImageModal',

    props: [
      'image'
    ],

    data() {
      return {
        imageLink: process.env.VUE_APP_IMAGE_BASE_URL,
      }
    }
  }
</script>