<template>
  <div class="home">
    <HeaderTemplate v-if="languageSet == true" @updateLang="updateLangChoice" />
    <OrderSearch v-if="getCurStage('orderSearch') && languageSet == true" @done="processOrder" :key="langChoice" />
    <ItemSelect v-if="getCurStage('itemSelect')" :order="orderData" :returnReasons="returnReasons" @done="processReturnResponse" @restart="reset" :key="langChoice" />
    <OfferReducedRefund v-if="action=='offer_reduced_refund' && getCurStage('processReturn')" :data="returnData" :returnReasons="returnReasons" @done="processReturnResponse" @restart="reset" :key="langChoice" />
    <FreeReturn v-if="action=='free_return' && getCurStage('processReturn')" :data="returnData" :returnReasons="returnReasons" @restart="reset" :key="langChoice" />
    <ReturnlessReturn v-if="action =='keep_items' && getCurStage('processReturn')" :data="returnData" :returnReasons="returnReasons" @restart="reset" :key="langChoice" />
    <CustomerPaid v-if="(action=='customer_paid_return' || action=='disposal') && getCurStage('processReturn')" :data="returnData" :returnReasons="returnReasons" @restart="reset" :key="langChoice" />
    <ContactCS v-if="action=='contact_cs' && getCurStage('processReturn')" :data="returnData" :key="langChoice" />
    <FooterTemplate v-if="languageSet == true" :key="langChoice+'f'" />
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderTemplate from '@/components/HeaderTemplate.vue';
import FooterTemplate from '@/components/FooterTemplate.vue';
import OrderSearch from '@/components/OrderSearch.vue';
import ItemSelect from '@/components/ItemSelect.vue';
import OfferReducedRefund from '@/components/OfferReducedRefund.vue';
import FreeReturn from '@/components/FreeReturn.vue';
import ReturnlessReturn from '@/components/ReturnlessReturn.vue';
import CustomerPaid from "@/components/CustomerPaid";
import ContactCS from "@/components/ContactCS";
import AlreadyReturned from '@/components/Modals/AlreadyReturned.vue';
import lang from '@/assets/translations/languages.json';
import ErrorModal from "@/components/Modals/ErrorModal.vue";

export default {
  name: 'HomeView',

  data() {
    return {
      stages: ['orderSearch','itemSelect','processReturn'],
      curStage: 'orderSearch',
      orderData: [],
      returnData: [],
      isLoading: false,
      action: '',
      languageSet: false,
      langChoice: 0,
      returnReasons: [],
      alertModalOpen: false,
    }
  },

  components: {
    HeaderTemplate,
    FooterTemplate,
    OrderSearch,
    ItemSelect,
    OfferReducedRefund,
    FreeReturn,
    ReturnlessReturn,
    CustomerPaid,
    ContactCS
  },

  mounted()
  {
    this.languageSet = this.$tools.setLangData(lang);
    this.getReturnReasons();
  }, 

  methods:
  {
    getReturnReasons()
    {
      // This method will run each time the language is changed
      // For the initial reason populate, when the page first loads, we can have it run in the background
      if(this.curStage != 'orderSearch')
        this.isLoading = true;

      this.returnReasons = [];
      let curLocale = this.$tools.getLocaleName();
      
      this.$api.get('get_reasons.php?locale=' + curLocale)
      .then(response => {
        let reasons = response.data.data.data;

        if(reasons.length == 0){
          this.openErrorMessage('olive655-' + curLocale);
        }
        if(curLocale == 'en_GB')
        {
          for(let reason of reasons)
          {
            let r = {
              id: reason.id,
              value: reason.en_value
            }
            this.returnReasons.push(r);
          }
        } else
        {
          for(let reason of reasons)
          {
            if(reason.translations.length == 0){
              this.openErrorMessage('olive655-' + curLocale);
            }
            for(let translation of reason.translations)
            {
              if( translation.locale.toLowerCase() == curLocale.toLowerCase())
              {
                let t = {
                  id: translation.return_reason_id,
                  value: translation.value
                }
                this.returnReasons.push(t);
              }
            }
          }
        }
        this.isLoading = false;
        if(this.returnReasons.length == 0)
        {
          this.openErrorMessage('lime270');
        }
      })
      .catch(() => {
        this.openErrorMessage('crimson532-' + curLocale);
        this.isLoading = false;
      });
    },
    processOrder(data)
    {
      if( data.returns.length > 0 )
      {
        // Double check if we have returned items selected
        if( data.returns.some(r => r.items.length == 0) )
        {
          this.openErrorMessage('indigo012');
        } else {
          this.$buefy.modal.open({
            parent: this,
            component: AlreadyReturned,
            hasModalCard: true,
            props: {
              order: data,
              reasons: this.returnReasons
            },
            events: {
              done: () => {
                this.orderProcessed(data);
              }
            }
          });
        }
      } else
      {
        this.orderProcessed(data);
      }
    },

    orderProcessed(data)
    {
      this.orderData = data;
      this.nextStage();
    },

    processReturnResponse(data)
    {
      this.nextStage();
      this.action = data.response_type;
      this.returnData = data;
    },

    getCurStage(stage){
      return stage == this.curStage;
    },

    nextStage()
    {
      if (this.curStage != 'processReturn')
      {
        let stageIndex = this.stages.indexOf(this.curStage);
        this.curStage = this.stages[stageIndex + 1];
      }
    },

    reset()
    {
      this.curStage = this.stages[0];
      this.orderData = [];
      this.returnData = [];
      this.canProceed = false;
    },

    updateLangChoice(langId)
    {
      this.langChoice = langId;
      this.getReturnReasons();
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        canCancel: false,
        props: {
          errorCode: errorCode
        }
      });
    },
  }
}
</script>
