import Vue from 'vue'
import router from './router'
import store from './boilerplate/store'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import EventBus from "./boilerplate/js/eventbus";
import Api from './boilerplate/js/api';
import Tools 	from './boilerplate/js/tools';
import App from './App.vue';

Vue.use(Buefy);

Vue.config.productionTip = false

// global events
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function() {
      return EventBus
    }
  }
});

Vue.prototype.$apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

// adaptor for axios calls + caching
Vue.prototype.$api = Api;

Vue.prototype.$tools = Tools;

//Flag to show debugging
Vue.prototype.$debug = true;
Vue.prototype.$errorModalOpen = Vue.observable({
  state: false
});

// shortcut around the app to show a success message
Vue.prototype.$success = function(message) {
  this.$buefy.snackbar.open({
    message,
    type: 'is-success'
  });
};

// shortcut around the app to show an error message
Vue.prototype.$error = function(message) {
  this.$buefy.snackbar.open({
    message,
    type: 'is-danger'
  });
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
