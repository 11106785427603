import mainRoutes from './main';
import Home from "../../views/HomeView.vue";
import Error404 from "../../boilerplate/views/Error404Page.vue";

/**
 * Recursively build a flat routes array.
 *
 * Any routes in a route's 'items' prop get added to top array.
 * This allows us to separate routes into files for nav.
 */
function makeRoutesArray(routes) {
  let flatRoutes = [];

  routes.forEach(r => {
    let routeItems = [];
    if (r.items) routeItems = makeRoutesArray(r.items);
    // router does not care about items
    delete r.items;
    flatRoutes.push(r);
    flatRoutes = flatRoutes.concat(routeItems);
  });

  return flatRoutes;
}

// define top level route
let routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    label: 'Home'
  }
];

// merge in routes - these will refer to other routes files via 'items'
routes = routes.concat(mainRoutes);

// catch all goes at the end
routes = routes.concat([
  {
    path: '/*',
    component: Error404,
    name: 'error404',
    label: 'Error404'
  }
]);

// export a flattened version
export default makeRoutesArray(routes);