/**
 * helpers/data.js
 * Just a class for storing the user, and various methods.
 */
class Tools {

  setLanguage(locale)
  {
    this.locale = locale;
  }

  setLangData(data)
  {
    this.langData = data;
    return true;
  }

  getLocaleId()
  {
    return this.locale ? this.locale : 0;
  }

  getLocaleName()
  {
    return this.langData[this.getLocaleId()].locale;
  }

  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }

  replace() {

  }

  ucWords(string) {

    if ( string == '' )
      return;

    var phrase = '';
    phrase += string[0].toUpperCase();

    for ( var i = 1; i <= string.length - 1; i++ ) {
      if ( string[i] >= 'A' && string[i] <= 'Z' )
        phrase += ' ';

      phrase += string[i];
    }

    return phrase;
  }

  arrayColumn(array, column) {

    var newArray;
    newArray = [];

    array.forEach(row => {
      if ( newArray.indexOf(row[column]) > -1 )
        return;
      newArray.push(row[column]);
    });

    return newArray;
  }

  // if multidimentional array give a column name, if plain array don't give
  arraySum(array, column = false) {

    var sum;
    sum = 0;

    // row or value
    array.forEach(row => {

      if ( column ){
        sum += parseInt(row[column]);

      } else {
        sum += parseInt(row);
      }
    });

    return sum;
  }

  // if multidimentional array give a column name, if plain array don't give
  arrayColumnsSum(array, columns = []) {

    var sum;
    sum = 0;

    // row or value
    array.forEach(row => {

      let count = true;
      let miniSum = 0;

      columns.forEach(column => {

        if ( row[column] == 0 ) {
          count = false;
        }

        miniSum = row[column];
      });

      if ( count == true ) {
        sum += parseInt(miniSum);
      }
    });

    return sum;
  }

  download(data) {

    let supported = {
      'base64pdf' : 'pdfFromBase64',
      'multiArrayCsv' : 'csvFromArray'
    };

    try {

      var fileName = data.fileName;
      if ( !fileName || fileName.length == 0 ) {
        fileName = "NewDocument.pdf";
      }

      var downloadLink = document.createElement("a");

      var linkSource = this[supported[data.type]](data);

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    } catch(error) {
      alert("Undefined function. \n" + error)
    }

  }

  pdfFromBase64(data) {
    return `data:application/pdf;base64,${data.data}`;
  }

  csvFromArray(data) {
    var csv = '';
    data.data.forEach(row => {
      csv += row;
      csv += '\n';
    });
    var csvData = encodeURI(csv);

    return `data:text/csv;charset=utf-8,${csvData}`;
  }

  /**
   * 
   * @returns the corresponding page text from the languages.json file
   */
  translatePage(filter = null, modalRef = null)
  {
    if(this.langData) //ie, if we have content in the languages.json file
    {
      let text = ''; // Reset the text to be returned

      // Since modals need to work on top of page content, we need to separate out the modal and page content
      if(modalRef != null)
      {
        // get all the modals for the given language
        for(let modal of this.langData[this.getLocaleId()].modals)
        {
          let dataRef = modal.ref; // let's make the languages reference easier to use 
          if(dataRef == modalRef) // if the modalRef exists in the language file for this language
          {
            let el = document.querySelectorAll("[data-ref='"+dataRef+"'");
            if(el.length)
              text = modal.text;
          }
        }
      }
      else if(filter == null)
      {
        // get all the pages for the given language
        for(let page of this.langData[this.getLocaleId()].pages)
        {
          let pageRef = page.class; // let's make the languages reference easier to use
          let el = document.getElementsByClassName(pageRef);
          if(el.length > 0)
          {
            if(text == '')
            {
              text = page.text; // create the first element 
            } else
            {
              text.concat(page.text); //.. or add to it, if it already exists
            }
          }
        }
      } else {
        // This finds anything that's not in pages or modals, currently that's 'general', 'emailText', and 'footer'
        // These have to be retrieved when pages and/or modals are mounted, or are already loaded, and can be called on the fly 
        text = this.langData[this.getLocaleId()][filter].text;
      }
      if(!text){
        return false;
      }
      return text;
    }
  }


  /**
   * Called each time a piece of text is required on screen. Looks through the page's text for the given reference 
   * @param {*} txtArray the object from languages.json corresponding to 'general' or the current page 
   * @param {*} ref the key for the piece of text you are looking for
   * @returns the relevant text from the json
   */
  renderText(txtArray, ref)
  {
    let text = '';
    text = txtArray.find(t => t[ref]);
    if(text)
      return text[ref];
    else
      return 'blue561_' + ref + '-' + this.getLocaleName();
  }
  
  /**
   * Used to populate the language selection menu, based on the languages in the json file
   * @returns supportedLanguages
   */
  getLangs()
  {
    let supportedLanguages = [];
    for(let lang of this.langData)
    {
      supportedLanguages.push(lang.nice_name);
    }
    return supportedLanguages;
  }

  setLangFromRef(navUserLanguage)
  {
    navUserLanguage = navUserLanguage.replace('-','_');

    for(let lang of this.langData)
    {
      if(lang.locale.includes(navUserLanguage))
      {
        this.setLanguage(lang.id);
      }
    }
  }

  validEmail(customerEmail)
  {
    let valid = String(customerEmail).toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return valid;
  }
}

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter( key => predicate(obj[key]) )
    .reduce( (res, key) => (res[key] = obj[key], res), {} );

export default new Tools;