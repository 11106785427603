/**
 * Axios - HTTP requests library.
 *
 */
import axios  from 'axios';
import EventBus from '../eventbus';

// set base URL for API requests
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || '';
axios.defaults.withCredentials = true;

// if for any reason api can't find loged in user, usually session expired, take action to log in again
axios.interceptors.response.use(function(response) {

  if (response.data.message) {
    EventBus.$buefy.snackbar.open({
      message: response.data.message,
      type: response.data.status === 'success' ? 'is-success' : 'is-danger'
    });
  }

  return response;
}, function(error) {

  if (error.response.data.message) {
    EventBus.$buefy.snackbar.open({
      message: error.response.data.message,
      type: error.response.data.status === 'success' ? 'is-success' : 'is-danger'
    });
  }

  // return the error response to the interface
  return Promise.reject(error);
});

// set common headers for all requests
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
// so server can detect whether requests are from the UI
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default axios;
