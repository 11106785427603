<template>
  <div class="captcha column">
    <div class="mainCaptcha">
      <div class="column is-1 has-text-centered captchaBg monospace">{{ givenCaptcha }}</div>
      <b-input class="column is-2 monospace has-text-centered mt0 p0" type="text" v-model="userCaptcha" :placeholder="getText('o006')" :disabled="complete == true"></b-input>
      <b-icon v-if="result == true" class="" type="is-success" icon="check" />
      <b-icon v-if="result == false" class="" type="is-danger" icon="alert" />
    </div>

    <div class="mainCaptcha">
      <div class="column is-1 has-text-centered">
      <b-tooltip :label="complete == true ? '': getText('o007')" position="is-right">
        <b-button class="button bg small" @click.stop="generateCaptcha" :disabled="complete == true">
          <b-icon icon="refresh" ></b-icon>
        </b-button>
      </b-tooltip>
      </div>

      <div class="column is-2 has-text-centered">
        <b-button class="button bg" @click.stop="validateCaptcha" :disabled="complete == true">{{getText('o008')}}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
export default {
  name: 'CaptchaControl',

  props: [
    'text',
    'errLogged'
  ],

  data() {
    return {
      givenCaptcha: '',
      userCaptcha: '',
      result: 'unset',
      complete: false,
      errorOpen: false,
    }
  },

  mounted(){
    this.generateCaptcha();
  },


  methods:
  {
    getText(ref){
      let t = this.$tools.renderText(this.text,ref);
      if( t.indexOf('blue561')>-1 == true )
      {
        if(this.errLogged == false)
          this.openErrorMessage(t);
      } else
      {
        return t;
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },

    generateCaptcha()
    {
      this.result = 'unset';
      let alpha = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M',
                            'N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
                            'a','b','c','d','e','f','g','h','i','j','k','l','m',
                            'n','o','p','q','r','s','t','u','v','w','x','y','z',
                            '1','2','3','4','5','6','7','8','9');
      let i, a, b, c, d;
      for ( i = 0; i < 4; i ++ )
      {
        a = alpha[Math.floor(Math.random() * alpha.length)];
        b = alpha[Math.floor(Math.random() * alpha.length)];
        c = alpha[Math.floor(Math.random() * alpha.length)];
        d = alpha[Math.floor(Math.random() * alpha.length)];
      }
      let code = a + '' + b + '' + c + '' + d;
      this.givenCaptcha = code;
    },

    validateCaptcha()
    {
      let string1 = this.removeSpaces(this.givenCaptcha);
      let string2 = this.removeSpaces(this.userCaptcha);
      if (string1 == string2){
        this.result = true;
        this.complete = true;
        this.$emit('done');
      }
      else{      
        this.result = false;  
      }
    },
    removeSpaces(string){
      return string.split(' ').join('');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .mainCaptcha { display: flex; gap:20px;align-items:center;}
</style>
