<template>
  <div class="modal-card" data-ref="confirmSelection">
    <header class="modal-card-head">
      <h2>{{ getText('cf001') }}</h2>
    </header>
    <section class="modal-card-body">
      <b-field>{{ getText('cf002') }} <strong>{{data.external_id}}</strong></b-field>
        <div v-for="(item, i) in data.items" :key="i" class="columns">
          <div class="column is-1">
            {{ item.qtyToReturn }} x
          </div> 
          <div class="column">
            {{ item.description }}
          </div>
          <div class="column">
            Reason: {{ getReason(item.reason) }}
          </div>
      </div>
    </section>
    <footer class="modal-card-foot btnBar btnBarRight">
      <button class="button bg" @click.stop="$parent.close()">{{ getText('cf003') }}</button>
      <button class="button bg emphasis" @click.stop="confirm">{{ getText('confirm', true) }}</button>
    </footer>
  </div>
</template>

<script>
export default
{
  props:[
    'data',
    'reasons'
  ],

  data()
  {
    return {
      pageText: [],
      generalText: [],
    }
  },

  mounted(){
    this.pageText = this.$tools.translatePage(null,'confirmSelection');
    this.generalText = this.$tools.translatePage('general');
  },

  methods:
  {
    getText(ref, general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == false )
          return t;
      }
    },

    confirm()
    {
      this.$parent.close();
      this.$emit('confirmed');
    },

    getReason(id)
    {
      return this.reasons.find(reason => reason.id == id).value;
    }
  }
}
</script>
<style scoped>

</style>