<template>
  <header class="header bgBlk">
    <div class="container mt1 mb1">
      <div class="column btnBar">
          <img src="@/assets/pertembaLogo.png" class="imgLink" @click.stop="reload">
        <b-select v-model="selectedLang" @input="setLang">
          <option v-for="(lang, index) of langs"
            :value="index"
            :key="index" >
            {{ lang }}
          </option>
        </b-select>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderTemplate',

  data() {
    return {
      langs: ['English'],
      selectedLang: '0'
    }
  },
  
  mounted()
  {
    this.langs = this.$tools.getLangs();
    this.setUserLang();
  },
  methods: {
    setUserLang()
    {
      // If the navigator language is not in languages.json then the default is English
      let navUserLanguage = window.navigator.userLanguage || window.navigator.language;
      this.$tools.setLangFromRef(navUserLanguage);
      this.selectedLang = this.$tools.getLocaleId();
    },

    setLang()
    {
      this.$tools.setLanguage(this.selectedLang);
      this.$emit('updateLang',this.selectedLang);
    },

    reload()
    {
      this.$router.go();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  height: 90px;
  display: flex;
  align-items: center;
}
</style>
