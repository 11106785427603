<template>
  <div class="pageContent contactCS">
		<div class="container mt3 mb3">
			<div class="columns">
				<div class="column is-7">
					<h1>{{ getText('c001') }} {{ data.external_id }}</h1>
					<p class="mt1">{{ getText('c002') }}</p>
					<ul>
						<li>{{ getText('c003') }}</li>
						<li>{{ getText('c004') }}</li>
						<li>{{ getText('c005') }}</li>
					</ul>
					<p class="mt1">{{ getText('c006') }}</p>
				</div>
				<div class="column">
					<ReturnItemSummary :items="data.items" />
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import ReturnItemSummary from "@/components/ReturnItemSummary";

export default {
  name: 'ContactCS',

  components: {
    ReturnItemSummary
	},

  data() {
    return {
      pageText: [],
      generalText: []
    }
  },
  props: [
    'data',
  ],

  computed: {
    
  },

  mounted()
  {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
  },

  methods:
  {
		getText(ref,general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	ul {list-style: disc;margin-left:1em;}
</style>
