import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiBaseUrl: '',

    isDarkTheme: ((localStorage['isDarkTheme']) && localStorage['isDarkTheme'] == 'true') || false,
    isLoading: true,
    isLoggedIn: false,

    sessionExpired: false,
    permissions: { }
  },
  mutations: {
    /**
     * Toggle dark theme.
     *
     */
    toggleDarkTheme(state) {
      state.isDarkTheme = !state.isDarkTheme;
      localStorage['isDarkTheme'] = state.isDarkTheme;
    },

    /**
     * Set loading, alters app visible state.
     *
     */
    loading(state) {
      state.isLoading = true;
    },

    /**
     * Set loaded, alters app visible state.
     *
     */
    loaded(state) {
      state.isLoading = false;
    },
  },
  actions: {},
  modules: {}
})
