<template>
  <div>
    <div class="container section has-text-centered">
      <h1 class="title has-text-weight-light badabing-error-main">404: Page Not Found</h1>
      <figure class="image">
        <img src="@/assets/404.jpeg" style="width: 200px; margin: auto" />
      </figure>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    }
  };
</script>

<style>
  .badabing-error-main {
    font-size: 7rem !important;
    padding-top: 7rem;
  }
</style>