/**
 * Wrapper for API requests.
 *
 * This mainly exists to decouple code from axios.
 * We can also use for things like:
 * - alias API requests paths (so the code doesn't know the actual URL)
 * - making requests to various sources via aliases
 * - cache data / manage expiry of cached data, getting from store
 */

import axios from '../axios';

/**
 * Define cacheable stuff.
 *
 * Have made it an object in case of setting timeouts etc.
 * But nothing so far.
 */
const cacheable = {
  channels: {},
  fields: {},
  locales: {},
  types: {},
  purposes: {}
};

class Api {
  constructor() {
    this.service = axios.create({});

    this.service.interceptors.response.use(response => {
      // we only care about get requests for caching
      if (response.config.method !== 'get') return response;

      // we want the path to check cache - parse out the baseURL from the url
      let url = response.config.url;
      let baseURL = response.config.baseURL;

      // get the path - plus one for the leading slash - this is what is cached
      let path = url.substring(baseURL.length + 1, url.length);

      // get the params in the original format, stringified
      let params = {params: response.config.params};
      let paramsString = JSON.stringify(params);

      // cache it if appropriate
      if (this.isCacheable(path, paramsString)) this.setCached(path, paramsString, response);

      return response;
    }, error => {
      return Promise.reject(error);
    });

    this.cacheable = cacheable;

    this.cache = {};
  }

  /**
   * Cache stuff
   *
   */

  clearCache(path) {
    if (Object.prototype.hasOwnProperty.call(this.cache, path)) delete this.cache[path];
  }

  getCached(path, paramsString) {
    if (!this.hasCached(path, paramsString)) return null;

    return this.cache[path][paramsString].data;
  }

  setCached(path, paramsString, data) {
    // ensure path has a key in the cache
    if (!Object.prototype.hasOwnProperty.call(this.cache, path)) this.cache[path] = {};

    // set path paramsString key to the data
    this.cache[path][paramsString] = {
      received: Date.now(),
      data
    };
  }

  hasCached(path, paramsString) {
    let hasCached = Object.prototype.hasOwnProperty.call(this.cache, path);

    if (hasCached) hasCached = Object.prototype.hasOwnProperty.call(this.cache[path], paramsString);

    // check the cache has a property for the path
    if (!Object.prototype.hasOwnProperty.call(this.cache, path)) return false;

    // check the cache for the path has a property for the params
    return Object.prototype.hasOwnProperty.call(this.cache[path], paramsString);
  }

  isCacheable(path) {
    return Object.prototype.hasOwnProperty.call(this.cacheable, path);
  }

  /**
   * HTTP stuff
   *
   */

  delete(path) {
    return this.service.delete(path);
  }

  get(path, params = {}) {
    // do we need to check the cache
    if (this.isCacheable(path)) {
      let paramsString = JSON.stringify(params);
      let cached = this.getCached(path, paramsString);

      if (cached) {
        let promise = new Promise(function (resolve) {
          resolve(cached);
        });

        return promise;
      }
    }

    return this.service.get(path, params);
  }

  /**
   * Proxy for axios patch.
   *
   */
  patch(path, body) {
    return this.service.patch(path, body);
  }

  /**
   * Proxy for axios post.
   *
   */
  post(path, body) {
    return this.service.post(path, body);
  }

  /**
   * Proxy for axios put.
   *
   */
  put(path, body) {
    return this.service.put(path, body);
  }
}

export default new Api;
