<!--
  ImageModal.vue
  This one just opens a large verssion of any product image
-->

<template>
  <div class="modal-card" data-ref="alreadyReturned">
    <header class="modal-card-head">
      <div>
        <h2>{{order.external_id}} - {{ getText('ar001') }}</h2>
        <p>{{ getText('ar002') }}</p>
        <p v-if="canReprint == true">{{ getText('ar003') }}</p>
        <p v-if="canReturn == true">{{ getText('ar004') }}</p>
        <p v-else><b>{{ getText('ar005') }}</b></p>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="returnedItems" v-for="returnData in order.returns" :key="returnData.id">
        <b-table :data="returnData.items">
            <b-table-column field="image" centered v-slot="props">
              <button class="button is-small imgThumb" style="height: 100%" @click.stop="largeImage(props.row.image)">
                <img :src="imageLink + props.row.image">
                <b-icon icon="magnify-plus-outline" size="is-small" type="is-info" style="position: absolute; bottom: 0; right: 0; margin-right: 0;"></b-icon>
              </button>
            </b-table-column>

            <b-table-column field="description" v-slot="props">{{ props.row.description }}</b-table-column>
            
            <b-table-column field="qty" v-slot="props"> {{ props.row.returnedQty }}</b-table-column>

            <b-table-column field="reason" v-slot="props">{{ getReturnReasonText(props.row.returnReasonId) }}</b-table-column>
        </b-table>
        <div class="btnBar mt025">
          <b-button v-if="canReprint(returnData)" class="bg ml075" @click.stop="printPaperwork(returnData)"><b-icon class="is-inline-block mr025 vab mtm1" icon="printer"></b-icon> {{ getText('reprint', 'general')}}</b-button>
          <div v-else-if="isJson(returnData)">
            <div v-for="row in textData[returnData.id]" :key="row.id">{{row}}</div>
          </div>
          <p v-else-if="returnData.type == 'keep_items'">{{ getText('ar006') }}</p>
          <p v-else>{{ getText('ar007') }}</p>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot btnBar btnBarRight">
      <b-button v-if="canReturn == true" class="button bg" @click.stop="returnMore">{{ getText('continue', 'general')}}</b-button>
    </footer>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
  import ErrorModal from "@/components/Modals/ErrorModal.vue";
  import ImageModal from '@/components/Modals/ImageModal';
  export default {
    name: 'AlreadyReturned',

    props: [
      'order',
      'reasons'
 
    ],

    data() {
      return {
        canReturn: true,
        imageLink: process.env.VUE_APP_IMAGE_BASE_URL,
        pageText: [],
        generalText: [],
        errorText: [],

        isLoading: false,
        paperworkUrl: [],
        textData: [],
      }
    },
    mounted(){
      this.pageText = this.$tools.translatePage(null,'alreadyReturned');
      this.generalText = this.$tools.translatePage('general');
      this.returnChecker();
    },

    methods: 
    {
      getText(ref,general = false)
      {
        let txtArray = general == false ? this.pageText : this.generalText;
        if(txtArray.length > 0){
          let t = this.$tools.renderText(txtArray,ref);
          if( t.indexOf('blue561')>-1 == true )
          {
            this.openErrorMessage(t);
          } else
          {
            return t;
          }
        }
      },

      openErrorMessage(errorCode)
      {
        this.$buefy.modal.open({
          parent: this,
          component: ErrorModal,
          hasModalCard: true,
          props: {
            errorCode: errorCode
          }
        });
      },

      returnChecker()
      {
        let itemChecker = [];
        let returnChecker = [];

        // Get the items in the order
        for(let item of this.order.items)
        {
          let toCheck = {
            "itemId": item.id,
            "qty": item.qty
          }
          itemChecker.push(toCheck);
        }

        // Get the items marked for return
        for(let returnedItems of this.order.returns)
        {
          for(let item of returnedItems.items)
          {
            let index = returnChecker.findIndex(el => el.itemId === item.id);
            if(index == -1)
            {
              let toCheck = {
                "itemId": item.id,
                "qty": item.returnedQty
              }
              returnChecker.push(toCheck);
            } else
            {
              returnChecker[index].qty += item.returnedQty;
            }

          }

          // Now check the label data
          if (returnedItems.courier_label_type == 'JSON')
            this.processLabelData(returnedItems.id, returnedItems.label, returnedItems.created);

        }

        //Now sort both arrays to get them in a similar order, so that they can be compared
        itemChecker.sort((a,b) => {
          return a.itemId - b.itemId; 
        });
        
        returnChecker.sort((a,b) => {
          return a.itemId - b.itemId; 
        });
        // Now compare them and return the result
        this.canReturn = JSON.stringify(itemChecker) !== JSON.stringify(returnChecker);

        
      },

      getReturnReasonText(id = '1')
      {
        return this.reasons.find(r => r.id == id).value;
      },

      returnMore()
      {
        this.$parent.close();
        this.$emit('done');
      },

      canReprint(data)
      {
        let reprintTypes = ['free_return','customer_paid_return','disposal'];
        let labelFormats = ['PDF', 'PNG'];
        return reprintTypes.indexOf(data.type) > -1 && labelFormats.indexOf(data.courier_label_type) > -1;
      },

      isJson(data)
      {
        return data.courier_label_type == 'JSON'
      },

      processLabelData(id,labelData,created = null)
      {
        let arr = {
          'text': '',
          'date': '',
          'created': '',
          'action': ''
        };

        let jsonData = JSON.parse(labelData);

        let values = Object.values(jsonData);
        let isInPost = values.indexOf('InPost') >= 0;
  
        Object.entries(jsonData).forEach(entry => {
          let [key,value] = entry;

          if (!isNaN(Date.parse(value))) {
            value = this.formatDate(value, true);
            arr['date'] = this.getText(key, 'general') + ' ' + value;
          } 
          else if(typeof(value)=='boolean') {
            if ( isInPost ) {
              arr['action'] += this.getText('ar009');
            } else {
              arr['action'] += this.getText(key, 'general');
            }
          } else {
            arr['text'] += this.getText(key, 'general') + ' ' + value;
          }
        });

        if (created)
        {
          let date = this.formatDate(created);
          arr['created'] = this.getText('ar008') + date;
        }

        this.textData[id] = arr;
      },

      formatDate(date, time = null)
      {
        let d = new Date(date);
        let t = time ? ' ' + d.toLocaleTimeString() : '';
        return d.toLocaleDateString() + t;
      },

      printPaperwork(returnData)
      {
        if(!returnData.label)
        {
          // No courier label, so customer pays for the return which could also cover disposal
          this.getReturnSlip(returnData);
        }
        else
        {
          let html = '';
          if(returnData.courier_label_type == 'PDF')
          {
            let url = "data:application/pdf;base64," + returnData.label;
            html = '<iframe width="100%" height="100%" src="'+ encodeURI(url) + '"></iframe>';
          }
          else 
          {
            let image = new Image();
            image.src = "data:image/png;base64," + returnData.label;
            html  = '<style>html,body{width: 210mm;height:297mm;}img{max-height:100%;max-width:100%;}</style>';
            html += '<img src="' + image.src + '" />';
          }
          let w = window.open();
          w.document.title = 'Pertemba Returns Label';
          w.document.body.innerHTML = html;

          if(returnData.courier_label_type != 'PDF') {
            setTimeout(()=>{
              w.print();
              w.close();
            },500 );
          }
        }
      },

      addRefundReasonText(items)
      {
        for(let item of items) 
        {
          item['reasonText'] =  this.reasons.find(r => r.id == item.returnReasonId).value;
        }
      },

      getReturnSlip(returnData)
      {
        // Check if we have already got this return slip
        if(this.paperworkUrl[returnData.id])
        {
          window.open(this.paperworkUrl[returnData.id]);
        }
        else
        {
          this.isLoading = true;

          this.addRefundReasonText(returnData.items);

          let data = {
            orderId: this.order.id,
            externalOrderId: this.order.external_id,
            itemsToRefund: returnData.items,
            type: returnData.type
          }

          this.$api.post('basic_return_paperwork.php', data)
          .then(response => {
            if(response.status == 200 && response.data.status == 'success')
            {
              const blob = this.dataURItoBlob(response.data.file);
              this.paperworkUrl[returnData.id] = URL.createObjectURL(blob);
              window.open(this.paperworkUrl[returnData.id]);
            } else {
              this.openErrorMessage('magenta626-1');
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.openErrorMessage('magenta626-2');
            this.isLoading = false;
          });
        }
      },

      dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        return blob;
      },
      
      largeImage(image)
      {
        this.$buefy.modal.open({
          component: ImageModal,
          props: {
            image: image
          }
        });
      },
    }
  }
</script>