<template>
  <div class="modal-card" data-ref="error">
    <header class="modal-card-head">
      <h2>{{ getText('err001') }}</h2>
    </header>
    <section class="modal-card-body">
      <div class="newlineStringStyle">
        <div v-if="errorCode == 'err500'">
          <p>{{ getText('err500') }}</p>
        </div>
        <div v-else>
          <p v-if="errorCode == 'err480'">{{ getText('err480') }}</p>
          <p v-else>{{ getText('err002') }}</p>
          <p>{{ getText('err003') }} <b>{{ errorCode }}</b>.</p>
        </div>

        <p class="mt1">{{ getText('err004') }}</p>
      </div>
    </section>
    <footer class="modal-card-foot btnBar btnBarRight">
      <button class="button bg" @click.stop="close">{{ getText('close', true) }}</button>
    </footer>
  </div>
</template>

<script>

export default {
  props: [
    'errorCode'
  ],

  data()
  {
    return {
      pageText: [],
      generalText: [],
    }
  },

  mounted(){
    this.checkState();
  },

  methods:
  {
    getText(ref, general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == false )
          return t;
      }
    },

    /**
     *  If this modal is not already open, then carry on, otherwise close it down immediately - we only want one error modal rendered 
    **/
    checkState()
    {
      if(this.$errorModalOpen.state == false){
        this.$errorModalOpen.state = true;
        this.pageText = this.$tools.translatePage(null,'error');
        this.generalText = this.$tools.translatePage('general');
      }
      else
      {
        this.$parent.close();
      }
    },

    close()
    {
      this.$errorModalOpen.state = false;
      this.$parent.close();
    }
  }
}
</script>
<style scoped>
  .newlineStringStyle{
    white-space: pre-wrap;
  }
</style>