<template>
  <div class="pageContent returnless">
    <div class="container mt3 mb3">
      <div class="columns">
        <div class="column is-7">
          <h1 v-if="isLoading">{{ getText('r001')}}</h1>
          <div v-else>
            <div v-if="data.reduced_refund_offer_accepted">
              <h1>{{ getText('r006')}}</h1>
              <p v-if="returnQty == 'single'">{{ getText('r007')}}{{data.refund_percentage}}{{ getText('r008a')}}</p>
              <p v-else>{{ getText('r007')}}{{data.refund_percentage}}{{ getText('r008b')}}</p>
            </div>
            <div v-else>
              <h1>{{ getText('r002')}}</h1>
              <p v-if="returnQty == 'single'">{{ getText('r003')}}</p>
              <p v-else>{{ getText('r004')}}</p>
              <p>{{ getText('r005')}}</p>
              <ul class="options">
                <li>{{ getText('r009') }}</li>
                <li>{{ getText('r010') }}</li>
                <li>{{ getText('r011') }}</li>
              </ul>
              <p>{{ getText('r012')}}</p>
            </div>
          </div>
        </div>
        <div class="column">
          <ReturnItemSummary :items="data.items" />
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import ReturnItemSummary from "@/components/ReturnItemSummary";

export default {
  name: 'ReturnlessReturn',
  
  components: {
    ReturnItemSummary
  },

  data() {
    return {
      
      isLoading: false,
      returnQty: 'single',
      pageText: [],
      generalText: [],
      emailSent: false,

    }
  },
  props: [
    'data',
    'returnReasons'
  ],

  computed: {
    
  },

  mounted() {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
    this.sendEmail();
    this.alreadyProcessed();
    this.getReturnQty();
  },

  methods:
  {
    alreadyProcessed()
    {
      /***
       * If the email has been sent, then to component refreshed (like when changing the language) 
       * we need a way to prevent the email from being sent over and over again.
       */
      if(this.data.emailSent == true)
      {
        this.emailSent = this.data.emailSent;
      }
    },

    getText(ref, general = false)
    {
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0)
      {
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    sendEmail()
    {
      this.isLoading = true;
      this.addRefundReasonText();
      let emailData = {
        orderId: this.data.id,
        itemsToRefund: this.data.items,
        reducedRefund: this.data.reduced_refund_offer_accepted == true,
        refundPercentage: this.data.refund_percentage
      };
      this.$api.post('send_cs_email.php', emailData)
      .then(response => {
        if(response.status == 200 && response.data.status == 'success')
        {
          this.emailSent = true;
        } else {
          this.openErrorMessage('coral795');
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.openErrorMessage('coral795');
        this.isLoading = false;
      });
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },

    addRefundReasonText()
    {
      for(let item of this.data.items) 
      {
        item['reasonText'] =  this.returnReasons.find(r => r.id == item.reason).value;
      }
    },

    getReturnQty()
    {
      let items = this.data.items;
      if(items.length > 1 || this.data.items[0].qtyToReturn > 1){
        this.returnQty = 'multiple';
      }
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  ul.options {margin: 0.5em 1em;}
</style>
