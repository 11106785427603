<template>
  <div id="app">
    <!-- <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <b class="has-text-primary">returnsportal</b>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item v-for="route in routes" :key="route.name" tag="router-link" :to="{ path: route.path }">
          {{ route.label }}
        </b-navbar-item>
      </template>
    </b-navbar> -->

    <router-view/>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
  export default {
    name: 'app',

    data() {
      return {
        routes: require('@/router/routes/main').default,
        isLoading: false
      }
    },

    computed: {
      
    },

    watch: {
      
    },

    mounted() {
      this.$store.state.apiBaseUrl = this.$apiBaseUrl;
      
    },

    methods: {
    }
  }
</script>

<style lang="scss">
  @import '@/assets/_shared.scss';
</style>
