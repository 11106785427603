<template>
  <footer class="footer bgBlk">
    <div class="container">
      <p><span @click.stop="openTerms" class="whtTxt link">{{ getText('f001') }}</span></p>
      <p>{{ getText('f002') }}</p>
      <hr/>
      <p>
        &copy; {{currentYear}} {{ getText('f003') }}
        <br />
        {{ getText('f004') }}
      </p>
    </div>
  </footer>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import TermsConditions from "@/components/Modals/TermsConditions";

export default {
  name: 'FooterTemplate',

  data(){
    return {
      currentYear: new Date().getFullYear(),
      footerText: []
    }
  },

  mounted()
  {
    this.footerText = this.$tools.translatePage('footer');
  },

  methods:
  {
    getText(ref){
      let txtArray = this.footerText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },

    openTerms(){
      this.$buefy.modal.open({
        parent: this,
        component: TermsConditions,
        hasModalCard: true
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  padding: 3rem 1.5rem!important;

  .container {
  text-align: center;
  }
}


</style>
