<template>
  <div class="border1 p1">
    <p class="has-text-weight-bold">{{ getText('youAreReturning') }}</p>
    <div class="is-flex">
      <div v-for="item in items" :key="item.id">
        <div class="imgThumbSml mr2">
          <img :src="imageLink + item.image">
          <div class="qtyMarker">{{ item.qtyToReturn }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'ReturnItemSummary',

  data() {
    return {
      imageLink: process.env.VUE_APP_IMAGE_BASE_URL,
      
      generalText: []
    }
  },
  props: [
    'items',
  ],

  computed: {
  },

  mounted()
  {
    this.generalText = this.$tools.translatePage('general');
  },

  methods:
  {
    getText(ref){
      let txtArray = this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
