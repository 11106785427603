import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  base: '/',
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes: routes
})

// prevent access to routes except for authed users
router.beforeEach((to, from, next) => {
  // allow everyone to access home route
  if (to.path === '/') next();

  // todo: check user is authed
  // todo: check user has access to new route

  // redirect non authed people to home
  // if (!user) {
  //   next('/');
  // } else {
  next();
  // }
});

export default router
