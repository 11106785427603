<template>
  <div class="pageContent itemSelect">
    <div class="container mt3 mb3">
      <h1>{{order.external_id}} - {{ getText('i001') }}</h1>
      <p>{{ getText('i002') }}</p>
      <p>{{ getText('i003') }}</p>
      <p class="is-size-6">{{ getText('i004') }}</p>

      <b-table :data="order.items">
        
          <b-table-column field="selected" centered v-slot="props">
            <b-checkbox v-model="props.row.selected" v-bind:false-value="0" v-bind:true-value="1" :disabled="!checkBoxActive(props.row)"></b-checkbox>
          </b-table-column>

          <b-table-column field="image" centered v-slot="props">
            <button class="button is-small imgThumb" style="height: 100%" @click.stop="largeImage(props.row.image)">
              <img :src="imageLink + props.row.image">
              <b-icon icon="magnify-plus-outline" size="is-small" type="is-info" style="position: absolute; bottom: 0; right: 0; margin-right: 0;"></b-icon>
            </button>
          </b-table-column>

          <b-table-column field="description" v-slot="props">
            {{ props.row.description }}
          </b-table-column>
          
          <b-table-column field="qty" v-slot="props">
            <b-select v-model="props.row.qtyToReturn" @input="updateCheckbox(props.row)" :class="hiliteQty(props.row)" :disabled="remainingQty(props.row) ==0">
              <option value="0" selected>0</option>
              <option v-for="n in parseInt(remainingQty(props.row))" :value="n" :key="n">
                {{ n }}
              </option>
            </b-select>
          </b-table-column>

          <b-table-column field="reason" v-slot="props">
            <b-select v-model="props.row.reason" :disabled="props.row.qtyToReturn == 0" @input="updateCheckbox(props.row)" :class="hiliteReason(props.row)">
              <option v-for="reason in reasons" :value="reason.id" :key="reason.id">
                {{ reason.value }}
              </option>\
            </b-select>
          </b-table-column>

      </b-table>
      <div class="btnBar">
        <b-button class="button mt1 bg ml075" :loading="isLoading" @click.stop="startOver">{{ getText('restart', true) }}</b-button>
        <b-button class="button mt1 bg" :disabled="!canContinue" @click.stop="confirm">{{ getText('confirm', true) }}</b-button>
      </div>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import ConfirmReturnSelectionModal from "@/components/Modals/ConfirmReturnSelection";
import ImageModal from '@/components/Modals/ImageModal';

export default {
  name: 'ItemSelect',

  props: [
    'order',
    'returnReasons'
  ],
  data() {
    return {
      imageLink: process.env.VUE_APP_IMAGE_BASE_URL,

      reasons: [],
      possibleResponses: ['offer_reduced_refund','free_return','keep_items','customer_paid_return','contact_cs', 'disposal'],
      dataForReturn: [],

      // If $debug == true, the testResponse will override any returned from the api, unless testResponse = 'db'
      testResponse: 'db', // could also use any of the possibleResponses, or 'error'
      
      isLoading: false,
      pageText: [],
      generalText: []
    }
  },

  mounted()
  {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
    if(this.pageText == false || this.generalText == false)
      this.openErrorMessage('yellow267-'+this.$tools.getLocaleId());
    this.getReturnReasons();
  },

  watch: {
    returnReasons(){
      this.getReturnReasons();
    }
  },

  computed: {
    canContinue(){
      let items = this.order.items;
      let canContinue = true;
      let rowStatus = [];

      if(items && items.length >0){

        for (const item of items)
        { 
          let fieldCheck = 0;
          // Check the three fields that need to have a value to count the row as ready
          if ( item.selected == 1 ) fieldCheck ++;  
          if ( item.qtyToReturn > 0 ) fieldCheck ++;
          if ( typeof item.reason !== "undefined" && item.reason != '' ) fieldCheck ++;
          
          if ( fieldCheck == 3 ) // All three fields are ready
          {
            rowStatus.push('ready');
          }
          else if ( fieldCheck > 0 ) // Not all of the fields are complete
          {
            rowStatus.push('incomplete');
          }
        }

      // If we have only incomplete rows or we have no rows started we can't continue 
      if((rowStatus.indexOf('ready') == -1 && rowStatus.indexOf('incomplete') > -1) || rowStatus.length == 0)
      {
        canContinue = false;
      }
      }
      
      return canContinue;
    },
  },

  methods: 
  {
    getReturnReasons()
    {
      this.reasons = this.returnReasons;
    },

    getText(ref, general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },

    updateCheckbox(item)
    {
      if (item !== undefined) 
        item.selected = item.qtyToReturn > 0 && item.reason ? 1 : 0;
    },

    checkBoxActive(item)
    {
      if (item === undefined) return false; 
      return item.qtyToReturn > 0 && typeof item.reason !== "undefined";
    },

    confirm()
    {
      this.dataForReturn = JSON.parse(JSON.stringify(this.order));

      const items = this.dataForReturn.items.filter( item => {
        return (item['selected'] == 1 && item['qtyToReturn'] > 0 && item['reason']);
      });

      this.dataForReturn.items = items;
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: ConfirmReturnSelectionModal,
        hasModalCard: true,
        props: {
          data: this.dataForReturn,
          reasons: this.reasons
        },
        events: {
          confirmed:() => {
            this.selectionConfirmed();
          }
        }
      });
    },

    selectionConfirmed()
    {
      this.isLoading = true;
      let postData = {
        "orderId": this.dataForReturn.external_id,
        "reducedRefundOffered": false,
        "items": []
      }

      for (let item of this.dataForReturn.items)
      {
        let el = {
          "id": item.id,
          "quantity": item.qtyToReturn,
          "reasonId": item.reason,
          "costPrice": item.cost_price
        }
        postData.items.push(el);
      }
      if(this.$debug == true && this.testResponse != 'db')
      {
        if(this.testResponse == 'error')
        {
          this.openErrorMessage('salmon008');
        } else
        {
          this.dataForReturn.response_type = this.testResponse;
          this.dataForReturn.label = '';
          this.$emit('done', this.dataForReturn);
        }
        this.isLoading = false;
      } else
      {
        this.$api.post('route_return.php',postData)
        .then(response => {
          if (response.status == 200)
          {
            let responseData = response.data.data.data;
            if(!responseData)
            {
              if(response.data.data.message.indexOf('return address') > -1)
              {
                this.openErrorMessage('tan388-' + this.dataForReturn.address_country.toUpperCase());
              }
              else if (response.data.data.message.indexOf('No available carrier') > -1)
              {
                this.openErrorMessage('brown235');
              }
              else
              {
                this.openErrorMessage('salmon008');
              }
            } else
            {
              if(this.possibleResponses.includes(responseData.response_type) > -1)
              {
                this.dataForReturn.response_type = responseData.response_type;
                this.dataForReturn.label = responseData.courier_label || '';
                this.dataForReturn['courier_label_type'] = responseData.courier_label_type; //pdf or png
                this.dataForReturn['drop_off_locator_url'] = responseData.drop_off_locator_url;
                this.dataForReturn['refund_percentage'] = responseData.refund_percentage;
                this.$emit('done', this.dataForReturn);
              } else
              {
                this.openErrorMessage('orange384');
              }
            }
            
            this.isLoading = false;
          }
        }).catch(() => {
          this.openErrorMessage('red493');
          this.isLoading = false;
        });
      }
    },
    
    startOver(){
      this.$emit('restart');
    },
    
    remainingQty(row)
    {
      let itemQty = [];
      for(let item of this.order.items)
      {
        let toCheck = {
          "itemId": item.id,
          "qty": item.qty
        }
        itemQty.push(toCheck);
      }
      
      for(let returnedItems of this.order.returns)
      {
        for(let item of returnedItems.items)
        {
          let index = itemQty.findIndex(el => el.itemId === item.id);
          if(index > -1)
          {

            itemQty[index].qty -= item.returnedQty;
          }
        }
      }
      let d = itemQty.find(i => i.itemId == row.id);
      // find the data set in itemQty and return the qty
      return Number(d.qty);
    },

    hiliteQty(row)
    {
      return row.qtyToReturn == 0 && typeof row.reason == "undefined" && this.remainingQty(row) > 0 ? 'hilite' : ''; 
    },

    hiliteReason(row)
    {
      return row.qtyToReturn > 0 && typeof row.reason == "undefined" ? 'hilite' : '';
    },

    largeImage(image)
    {
      this.$buefy.modal.open({
        component: ImageModal,
        props: {
          image: image
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .devTestMenu{
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    padding: 5px;
    background: #fff;
    border: 1px solid #2bbbb5;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.5);

    * {
      font-size: 16px!important;
      font-family: 'Comic Sans MS';
    }
  }
</style>
